@import '../abstracts/colors';

.agenda {
  height: 100%;

  .agenda-page {
    width: 100%;
    height: 100%;
    overflow: hidden;

    .display-linebreak {
      white-space: pre-line;
    }

    &.layout-sidebar-left {
      position: relative;
      display: flex;
      flex-direction: row;
      width: 100%;
      height: 100%;
      overflow: hidden;

      section:first-of-type {
        display: flex;
        flex-direction: column;
        flex-basis: 30%;
        height: 100%;
        min-width: 400px;
        overflow-y: auto;
        position: sticky;
        top: 0;
      }

      section:last-of-type {
        position: relative;
        flex-basis: 70%;
        height: 100%;
        background-color: #fff;
      }
    }

    .empty-state-container {
      box-shadow: none;
    }

    .agenda-entries__header {
      display: flex;
      justify-content: right;
      align-items: center;
      margin-top: 32px;
    }

    [data-theme=aqua] {
      .btn-skin--primary {
        border-color: white;
        background-color: white;
        color: $color-aqua-sidebar;

        i {
          color: $color-aqua-sidebar;
          font-size: 24px;
        }

        &:focus {
          box-shadow: 0 0 0 2px $color-nt-grey-900;
        }

        &:active,
        &:focus,
        &:hover:not([disabled]) {
          background-color: $color-aqua-900;
          color: white;

          i {
            color: white;
          }
        }
      }
    }

    &__border {
      position: absolute;
      top: 0;
      left: 0;
      width: calc(100% - 48px);
      height: calc(100% - 48px);
      margin: 24px;
      border: solid 8px #ae9132;
      pointer-events: none;
    }

    > article {
      flex-basis: 63.34%;
      overflow-y: scroll;
    }

    &__cover,
    &__schedule {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
    }

    &__schedule {
      margin: 0 64px;
      padding-right: 16px;
      max-height: 80%;
      overflow-y: auto;
      overflow-x: clip;

      &__list {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        font-size: 18px;
        line-height: 26px;

        &__item {
          position: relative;
          width: 100%;
          max-width: 700px;
          padding-left: 16px;
          margin-left: 12px;
          padding-bottom: 16px;
          border-left: solid 1px #05676e;

          &:last-child {
              border-left: solid 1px transparent;
              padding-bottom: 0;
          }

          &::before {
            content: '';
            display: block;
            position: absolute;
            left: -5px;
            width: 10px;
            height: 10px;
            background-color: #05676e;
            border-radius: 50%;
            transform: translateX(-0.5px);
          }

          h4 {
            width: max-content;
          }

          b {
            color: #05676e;
            font-size: 15px;
            line-height: 20px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
          }

          .agenda-header {
            margin-top: -10px;

            .agenda-title {
              width: calc(100% - 64px);
              overflow-wrap: break-word;

              .title-separator {
                font-size: 20px;
                color: $color-nt-grey-400;
                font-weight: 300;
                margin: 5px 4px 0px 4px;
              }

              .presenter-name {
                text-transform: none;
              }
            }

            .align {
              .delete-agenda-entry {
                color: $color-red-600;
              }
            }
          }
        }

      }
    }

    &__cover {
      height: 100%;
      padding: 34px 38px 80px 56px;

      &__empty {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        flex-grow: 1;
        height: calc(100% - 180px);
      }

      &__team {
        max-height: 520px;
        overflow-y: auto;

        &__presented-by {
          font-size: 18px;
        }

        &__members {
          font-size: 15px;
        }
      }

      &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(rgba(8, 84, 89, 0.8), rgba(8, 84, 89, 0.8)), url("../../Agenda/agenda.png");
        background-size: cover;
        z-index: -1;
      }

      &__title {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 100%;

        .agenda-meeting-detail-header {
          line-height: 22px;
          margin-bottom: 0;
        }

        h3 {
          line-height: 48px;
        }

        hr {
          width: 94%;
          margin: 24px 0;
          background-color: white;
        }
      }
    }

    .agenda-side-drawer {
      article {
        padding-left: 32px;
        padding-right: 32px;

        hr {
          margin: 0;
        }

        .layout-data-entry-form__field {
          label {
            flex-basis: 50%;
          }

          .time-dropdown {
            min-width: 96px;

            .dropdown__panel {
              height: 484px !important;
              overflow-y: scroll;
            }
          }
        }
      }
    }

    &__edit-meeting-details {
      width: 105%;
      display: flex;
      justify-content: space-between;

      img {
        margin-left: -18px;
      }

      button {
        border: none;
        transform: translateY(90%);
      }
    }
  }

  .client-meeting-agenda {
    display: flex;
    flex-direction: column;
    padding: 0 0 20px 48px;

    > section {
      width: 100%;
      max-width: 620px;
    }

    &__header {
      justify-content: flex-start !important;
    }

    .presenter-field {
      align-items: start !important;
    }

    .presenter-dropdown {
      .input__field {
        width: 252px;
      }
    }


    .layout-data-entry-form {
      &__field {
        margin-bottom: 15px;

        label {
          flex-basis: 40%;
        }
      }
    }

    .presenter-list-view {
      display: flex;
      flex-direction: column;

      &__fields {
        margin-bottom: 16px;

        :nth-child(1) {
          flex-grow: 1;
        }
      }
    }

    .external-presenter {
      margin-bottom: 16px;

      input {
        width: calc(100% - 80px);
      }

      .external-delete-button {
        display: flex;
        justify-content: end;

        .icon {
          vertical-align: middle;
          margin-top: -52px;
          line-height: inherit;
          margin-left: 705px;
        }
      }
    }



    .nt-delete-button {
      display: flex;
      justify-content: end;

      .icon {
        vertical-align: middle;
        margin-top: -52px;
        line-height: inherit;
        margin-left: 705px;
      }
    }

    .nt-presenter-input {
      input {
        width: calc(100% - 80px);
      }
    }

    .nt-presenter-dropDown {
      margin-right: 80px;
    }



  }

  .agenda-entry-form {
    &__field {
      margin-bottom: 8px;
      width: 100%;

      .item-title {
        width: 100%
      }

    }
  }
}

.button-flex{
  button {
    display: flex;
  }
}

.meetingDateField {
  width: calc(100% - 80px);

  .gpi-datepicker {
    margin-left: 32px;
  }
}