.asset-reliance-chart-status-line-container {
  display: flex;
  position: absolute;
  right: 0;
  left: 0;

  span {
    white-space: nowrap;
    padding-right: 8px;
    position: relative;
    top: 8px;
  }

  .horizontal-rule {
    position: absolute;
    left: 0;
    border-radius: 6px;
    margin-right: 0;
  }
}