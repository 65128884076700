.left-right-alert-banner {
  margin-bottom: 26px;

  .alert__actions {
    display: none;
  }

  .btn-skin--borderless {
    border: transparent;
  }
}


