.data-entry-summary {
  display: flex;
  flex-direction: column;
  background-color: $color-nt-grey-050;
  margin-bottom: $spacing-xl;

  &__title {
    display: flex;
    align-items: center;
    height: 56px;
    margin-bottom: $spacing-xl;
    background-color: $color-aqua-900;
  }

  &__item {
    display: flex;
    flex-direction: column;
    line-height: 20px;
    word-wrap: break-word;
    overflow-wrap: break-word;

    &:not(:last-child) {
      margin-bottom: $spacing-xl;
    }

    &__label {
      margin-bottom: $spacing-sm;
    }

    &__value--empty {
      opacity: 50%;
    }
  }

  &__title,
  &__items,
  &__custom-content {
    padding-left: $spacing-xxl;
    padding-right: $spacing-10;
    margin-bottom: $spacing-xl;
  }
}
