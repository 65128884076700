@import "../abstracts/colors";

.partially-owned-investment {
  .partially-owned-investment-container {
    padding: 16px 48px 24px;

    .search-bar-container {
      display: flex;
      justify-content: flex-start;
      flex-direction: row;
      gap: 16px;

      .search-button {
        display: flex;
        width: 139px;
        padding: 0px 16px;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 10px;
        flex-shrink: 0;
      }

      .btn-skin--borderless {
        border: none;
      }
    }

    .btn-skin--borderless {
      border: transparent;
    }

    .alert__content {
      .marginright-lg {
        margin-right: 5px;
      }
    }

    #search-input {
      flex-grow: 1;
    }

    .empty-state-container {
      margin: 0;
      box-shadow: none;
    }

    .partially-owned-investment-message {
      display: flex;
      width: 869px;
      flex-direction: column;
      align-items: center;
    }

    .partially-owned-investment-table {
      margin-top: 24px;
      background: var(--grey-050, #F6F6F6);

      .partially-owned-investment-grid {
        display: grid;
        grid-template-columns: 9fr 4fr 9fr 8fr 5fr 5fr 7fr;
        grid-column-gap: 16px;
        padding: 0 8px;
      }

      .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .partially-owned-investment-table-header {
        line-height: 150%;
        padding-top: 8px;
        padding-bottom: 8px;
        font-size: 13px;
        font-weight: 500;
        border-bottom: 1px solid $color-nt-grey-200;
        background-color: white;
      }

      .partially-owned-investment-table-row {
        padding: 16px 8px;
        line-height: 150%;
        font-size: 13px;
        font-weight: 400;
        border-bottom: 1px solid $color-nt-grey-100;

        &.add-account-row {
          text-align: right;

          button {
            margin: 0 16px;
          }
        }
      }
    }
  }
}

.edit-partially-owned-investment{
  .account-details {
    .layout-data-entry-form__field {
      padding-bottom: 20px;
      label {
        flex-shrink: 0;
      }
    }

    .underlined-header {
      justify-content: unset;
    }
  }

  .zero-out-of-estate-alert {
    border: none;
    background: none;
    color: #bf3b2a;
  }
}

.remove-partially-owned-investment-grid {
  display: grid;
  grid-template-columns: 12fr 4fr 4fr 4fr;
  grid-column-gap: 18px;
  line-height: 32px;
}

.search-bar-info-message {
  padding: 16px 0 24px 48px;
  width: 870px;
  display: flex;
  position: relative;
  bottom: 50px;
  align-items: flex-start;
  font-style: italic;
  align-self: stretch;
  white-space: break-spaces;
}