@import '../abstracts/colors';

.printable-view {
  position: relative;
  width: 100%;
  aspect-ratio: 1366 / 1055;
  background-color: white;

  .family-grid-table {
    height: 40px;
    display: grid;
    grid-template-columns: 618px 190px 30px;
    gap: 10px 20px;
    font-size: 15px;

    .beneficiaryGoalsActionMenu {
      margin-top: 3px;
    }

    > label {
      font-weight: 500;
    }

    > span {
      margin-top: 12px;
      margin-bottom: 12px;
    }

  }

  .sidebar {
    padding: 32px 48px 32px 48px;
    display: -ms-flexbox;
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  .report-content {
    height: 100%;
    padding-bottom: 66px;

    .net-worth-over-time-body {
      .net-worth-over-time-goal-table {
        .goals-table-values {
          &.goals-table-last-row {
            margin-right: 10px;
          }
        }
      }
    }

    .toggle-label{
      &__white {
        color: #565A5C;
      }
    }


    .set-portfolio-reserve-page {
      &__header {
        .set-portfolio-reserve-header-text {
          &__header-toggle-text {
            color: #565A5C;
          }
        }
      }
    }
  }

  .report-header {
    position: absolute;
    right: 24px;
    top: 12px;
    color: $color-nt-grey-500;
    z-index: 999;
  }

  .report-disclaimer {
    position: absolute;
    color: #565A5C;
    font-size: 10px;
    bottom: 0;
    line-height: 12px;
    height: 54px;
  }

  button,
  input[type=button],
  .dropdown,
  .eyebrow-label,
  .data-entry-side-drawer,
  .edit-asset-reliance-button-container {
    display: none;
  }

  .accordion-tables,
  .accordion,
  .table-row-container {
    .icon {
      visibility: hidden;
    }

    .accented-accordion-item__right-content {
      padding-right: 0;
    }
  }

  .layout-split-sidebar,
  .layout-split-left,
  .layout-sidebar-left {
    > section[data-theme=aqua] {
      background-color: $color-nt-grey-050;

      * {
        color: $color-nt-grey-900;
      }
    }
  }

  .presentation-pane-header {
    padding: 32px 48px;
    background-color: $color-nt-grey-100;
    color: $color-nt-grey-900;

    h1 {
      color: $color-nt-grey-900;
    }

    .display-name {
      display: none;
    }

    div[role="tabpanel"] {
      background-color: white;
    }
  }

  .current-proposed-page {
    .allocation-grid {
      border-top: none;
      margin-top: 0 !important;
      padding-top: 4px !important;
    }

    .total-allocation-font {
      font-weight: bold;
    }

    .footer {
      &.total-allocation {
        padding-left: 158px
      }
    }
  }

  .agenda {
    .agenda-page {
      &__schedule {
        max-height: 100%;

        &__list {
          &__item {
            .agenda-header {
              .agenda-title {
                margin-top: 5px;
                padding-bottom: 8px;
              }
            }
          }
        }
      }

      &__edit-meeting-details {
        img {
          margin-left: -16px;
        }
      }

      &__cover {
        padding: 54px 64px 38px 72px;
        &::before {
          background-image: none;
        }
        &__title {
          .agenda-meeting-detail-header {
            margin-top: 0;
          }
          hr {
            border-top: 1px solid $color-nt-grey-900;
          }
        }

        &__team {
          max-height: 100%;
          &__presented-by {
            font-size: 15px;
          }

        }

      }
    }
  }

  .asset-summary-page {
    .assets,
    .liabilities {
      font-size: 15px;
    }

    .assets-grid {
      display: grid;
      grid-template-columns: minmax(26%, 1fr) repeat(4, minmax(50px, 1fr)) 32px;
    }

    .current-net-worth-grid {
      display: grid;
      grid-template-columns: minmax(27%, 33%) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) minmax(10%, 55ch) 32px;
    }
  }

  .wealth-summary-page {

    .summary-page-content {
      padding: 48px;

      section {
        margin-bottom: 71px;
      }
    }

    .assets-grid {
      display: grid;
      grid-template-columns: minmax(22%, 30%) repeat(4, minmax(50px, 1fr));
      padding-right: 48px;
    }

  }

}

@media print {
  .printable-view {
    page-break-after: always;
  }
}


.net-worth-over-time-page-action-menu-options {
  display: flex;
  justify-content: space-between;

  input {
    accent-color: #3D4042;
  }

  .life-insurance-value-at-death-option {
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    width: 100%;
  }
}
