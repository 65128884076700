.life-insurance {

  .life-insurance-section {
    #cashValuetoFundGoals {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding-right: 16px;
      margin-bottom: 8px;

      label {
        flex-basis: 34%;
        margin-right: 16px;
      }
    }

    .asset-year-input{
      .input-inline-align--right input{
        padding-left: 10px;
        margin-right: -291px;
        margin-left: 190px;
        margin-top: -11px;
        margin-bottom: 7px;
      }
      .input-inline-label {
        right: -188px;
        bottom: 27px;
        font-weight: bold;
      }
      b{
        top: 19px !important;
        position: relative;
      }
    }
  }

}
