.personal-liability {

  #liabilityType,
  #interestOnly {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 16px;
    padding-right: 16px;
    margin-bottom: 8px;

    label {
      flex-basis: 33%;
      margin-right: 16px;
      margin-bottom: 0;
    }
  }

  .layout-data-entry-form__field > :last-child {
      flex-basis: 66%;
  }
}
