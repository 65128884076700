.running-total {

  &__section-header {
    display: flex;
    justify-content: space-between;
    height: 48px;
    line-height: 48px;
    padding-right: $spacing-2xl;
    padding-left: $spacing-xxl;

    .header-column {
      font-weight: 500;
    }

    .currency {
      color: $color-nt-grey-550;
    }
  }

  &__section {
    padding-right: $spacing-2xl;
    padding-left: $spacing-md + $spacing-xxl;
    padding-bottom: 10px;
    background-color: $color-nt-grey-050;

    .line-item-row {
      display: flex;
      justify-content: space-between;
      line-height: $spacing-xxl;
      margin-left: 12px;
    }

    .subsection-header {
      padding-top: 10px;
      border-top: 1px solid #B1B1B1;
      font-weight: 500;
    }
  }
}