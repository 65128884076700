@import '../abstracts/colors';

.asset-summary-page {
  .disclosure-needs-review-alert {
    color: #3d4042;
    padding: 6px 12px 6px 8px;
    align-items: center;
    gap: 7px;
    margin-left: 16px;
    border: 1px solid var(--yellow-500, #FABE0F);
    background: var(--yellow-100, #FFF4C0);
    font-size: 13px;
  }

  .asset-summary-content {
    padding: 48px;

    section {
      margin-bottom: 71px;

    }

    .page-action-menu-options {
      display: flex;
      justify-content: space-between;
    }
  }

  .info-icon{
    color: #3D4042;
  }

  .no-assets-placeholder.empty-state-container {
    margin-top: 160px;
    text-align: center;
    box-shadow: none;

    .add-an-asset-button {
      .dropdown__panel {
        text-align: left;
        left: 50%;
        right: auto;
        transform: translate(-50%, 0);
      }
    }
  }

  .no-holdings-placeholder {
    margin: auto;
    padding-top: 72px;
    box-shadow: none;

    button {
      margin: 5px;
    }
  }

  .page-overlay {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 500;
    width: 100%;
    height: 100%;
    overflow-y: auto;
    transform: translateZ(0);
    transition: right 5.0s ease-out, visibility 5.0s !important;
    background-color: white;

    &--open {
      right: 0;
      visibility: visible;
      overflow: hidden;
    }

    &--closed {
      right: -100%;
      visibility: hidden;
    }
  }

  .section-header {
    > * {
      margin: auto 0;
    }
  }

  .liabilities-list,
  .asset-list {
    .accordion-tables {
      li {
        list-style: none;
      }

      padding-top: 8px;
    }
  }

  .assets-header-grid-with-actionmenu {
    display: grid;
    grid-template-columns: minmax(22%, 30%) repeat(4, minmax(50px, 1fr)) 32px;
  }

  .assets-header-grid {
    display: grid;
    grid-template-columns: minmax(22%, 30%) repeat(4, minmax(50px, 1fr));
  }

  .assets-grid {
    display: grid;
    grid-template-columns: minmax(22%, 30%) repeat(4, minmax(50px, 1fr));
  }

  .assets-grid-with-actionmenu {
    display: grid;
    grid-template-columns: minmax(22%, 30%) repeat(4, minmax(0px, 1fr)) 32px;
  }

  .cursor-pointer {
    cursor: pointer;
  }

  .current-net-worth-grid {
    display: grid;
    grid-template-columns: minmax(22%, 1fr) repeat(4, minmax(50px, 132px));
  }

  .current-net-worth-header-grid-with-actionmenu{
    display: grid;
    grid-template-columns: minmax(22%, 1fr) repeat(4, minmax(50px, 132px)) 32px;
  }

  .current-net-worth-grid-with-actionmenu {
    display: grid;
    grid-template-columns: minmax(22%, 1fr) repeat(4, minmax(50px, 132px)) 32px;
  }

  .outOfEstate-grid {
    display: grid;
    grid-template-columns: minmax(22%, 1fr) repeat(4, minmax(50px, 132px)) 40px;
  }

  .future-inflow-section,
  .life-insurance-section {
    .assets-grid-table [role=cell]:first-child {
      padding-left: 49px;
    }
    .disclosure-needs-review {
      //styleName: 12px .font-sm/400;
      font-family: Roboto;
      font-size: 12px;
      font-weight: 400;
      line-height: 15px;
      letter-spacing: 0px;
      text-align: left;
      color: #AC5300;
    }
  }

  .assets-grid-table {
    [role="cell"] {
      position: relative;
      padding-left: 8px;
      padding-right: 8px;

      &:first-child {
        padding-left: 32px;
      }

      .cell-with-popover {
        display: flex;
        z-index: 1;
      }

      .cell-text-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }
    }

    .row-within-subheading {
      [role="cell"]:first-child {
        padding-left: 40px;
      }
    }

    .drag__icon {
      padding-top: 10px;
    }

    .draggable {
      top: auto !important;
      left: auto !important;
    }

    .assets-grid-table-header {
      line-height: 15px;
      border-bottom: 1px solid #eaeaea;
      align-items: center;
      height: 36px;

      [role="columnheader"] {
        padding-left: 8px;
        padding-right: 8px;
        font-family: RobotoCondensed, Roboto, sans-serif;
        color: #3d4042;
        font-size: 11px;
        font-weight: 400;
        font-stretch: condensed;
        text-transform: uppercase;
      }

      &[role="row"] > :first-child {
        padding-left: 32px;
      }
    }

    .asset-row-subheader {
      height: 48px;
      line-height: 48px;

      &[role="row"] > :first-child {
        padding-left: 32px;
      }
    }
  }

  .assets-grid-accordion-item,
  .assets-grid-table-header,
  .assets-grid-table-row {
    grid-column: 1 / -1;
    height: 48px;
    align-items: center;
    flex: 1;

    &:hover,
    &:focus {
      background-color: #e1fcff;
      outline: 0;
    }
  }

  .accordion-header-content {
    height: 64px;
    background-color: #fff;
    align-items: center;
    border-radius: 5px;

    & > * {
      padding-left: 8px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
    }

    .h4 {
      line-height: 35px;
    }

    .asset-accordion-header-caption {
      color: #767676;
      font-family: 'Roboto';
      font-style: italic;
      font-weight: 400;
      font-size: 15px;
      line-height: 22px;
    }
  }

  .account-table-delete-icon {
    margin-left: 102px;
  }

  .asset-table-delete-icon {
    margin-left: 116px;
  }
}

.asset-summary-popover-content {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  column-gap: 40px;
  line-height: 32px;
  font-size: 15px;
}

.asset-summary-popover {
  font-size: 15px;
  font-weight: 400;
  padding: 16px
}
.assets-grid-table-header_arrange {
  line-height: 15px;
  border-bottom: 1px solid #eaeaea;
  align-items: center;
  height: 36px;
  padding-left: 23px;

  [role="columnheader"] {
    padding-left: 8px;
    padding-right: 8px;
    font-family: RobotoCondensed, Roboto, sans-serif;
    color: #3d4042;
    font-size: 11px;
    font-weight: 400;
    font-stretch: condensed;
    text-transform: uppercase;
  }

  &[role="row"] > :first-child {
    padding-left: 27px;
  }
}

.current-net-worth-popover-text {
  font-weight: 400;
  padding-left: 0;
  left: -100px !important;
}